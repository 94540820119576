<template>
  <div class="my-content">
    <a-search-form
      ref="searchForm"
      v-model="form"
      :loading="loading"
      :render-data="renderData"
      @handleSearch="handleSearch"
      @handleReset="handleReset" />
    <div class="p16 pb0">
      <a-button type="primary" icon="plus" @click="showAddEdit = true; row = {}">新增</a-button>
    </div>
    <a-tables
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :page="page"
      @onChange="onChange"
    >
    </a-tables>
    <add-edit v-if="showAddEdit" :row="row" @cancel="showAddEdit = false; handleSearch()"/>
  </div>
</template>
<script>
  import API from '@/api'
  import AddEdit from "./addEdit"
  export default {
    components: {AddEdit},
    data() {
      const that = this
      return {
        form: {},
        loading: false,
        renderData: {
          keyword: {
            label: '关键字',
            labelCol: 4,
            wrapperCol: 20,
            span: 7,
            props: { placeholder: '请填写配置名、编码、描述' }
          }
        },
        columns: [
          { title: '配置名', dataIndex: 'name' },
          { title: '编码', dataIndex: 'code' },
          { title: 'value值', dataIndex: 'value' },
          { title: '排序', dataIndex: 'sort' },
          { title: '描述', dataIndex: 'tips' },
          {
            title: '状态',
            dataIndex: 'status',
            renderType: 'html',
            render: (row) => {
              return row.status === 1 ? '启用' : '禁用'
            }
          },
          {
            title: '操作',
            width: 220,
            dataIndex: 'handle',
            renderType: 'handle',
            childrens: [
              {
                label: '编辑',
                default(row) {
                  that.showAddEdit = true
                  that.row = row
                }
              },
              {
                label: '删除',
                style: 'color: red',
                default(row) {
                  that.showPwd = true
                  that.row = row
                }
              },
            ]
          }
        ],
        data: [],
        page: { current: 1, pageSize: 10, total: 0 },
        row: {},
        showAddEdit: false
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList() {
        try {
          this.loading = true
          const params = {
            page: this.page.current,
            pagesize: this.page.pageSize,
            ...this.form
          }
          const { list, total } = await this.$request({
            url: API.type.typeList,
            params
          })
          this.data = list
          this.page.total = total
        } finally {
          this.loading = false
        }
      },
      handleSearch() {
        this.page.current = 1
        this.getList()
      },
      handleReset() {
        this.form = {}
        this.handleSearch()
      },
      onChange(page) {
        this.page = page
        this.getList()
      }
    }
  }
</script>
